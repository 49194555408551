import { RateLimit } from '../analytics-api/ApiSettings.types';
import { Application, AppSlugs } from '../auth';
import { DateStr } from '../Date.types';
import { RoleAssignment } from '../org-admin';
import { PaginationOptions } from '../Pagination.types';

export type HcsAdminOrgFilters = Partial<{
  name: string;
  slug: string;
  active: boolean | null;
  activeSubscriptions: boolean | null;
  zAccountId: string;
  zAccountNumber: string;
  m3terAccountId: string;
  m3terAccountCode: string;
  salesforceAccountId: string;
  salesforceLeadId: string;
  hasM3ter: boolean | null;
  created_on: string;
  last_synced_with_m3ter: string;
}>;
export type HcsAdminOrgPagination = Partial<
  PaginationOptions & {
    sortBy: HcsAdminOrgFilterFields;
    sortOrder: 'ASC' | 'DESC';
  }
>;

export type HcsAdminOrgFilterFields = keyof HcsAdminOrgFilters;
export type HcsAdminOrgPaginationFields = keyof HcsAdminOrgPagination;

export interface HcsAdminOrgOptions {
  filters?: HcsAdminOrgFilters;
  pagination?: HcsAdminOrgPagination;
}
export type HcsAdminOrgApiFilterOps = 'like' | 'ilike' | 'eq' | 'neq';
export type HcsAdminOrgApiFilter =
  | {
      name: string;
      val: string;
      op: HcsAdminOrgApiFilterOps;
    }
  | {
      name: string;
      val: boolean;
      op: 'eq';
    };

export interface HcsAdminOrgApiParams {
  q: {
    filters?: HcsAdminOrgApiFilter[];
    customFilter?: { activeSubscriptions: boolean };
    orderBy: [{ field: HcsAdminOrgFilterFields; direction: 'asc' | 'desc' }];
  };
  page: number;
  resultsPerPage: number;
}

export type OrgResult = { id: number; name: string; slug: string };

export type HcsAdminRoleAssignment = Pick<RoleAssignment, 'roleId' | 'userId'>;

export interface HcsAdminOrgApiRateLimit extends RateLimit {
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
}

export interface HcsAdminOrgApiRequestContentLimit {
  componentLimit: number;
  propertyLimit: number;
  organizationId?: number;
  id?: number;
}

export enum HcsAdminOrgApiRateLimitMultiplier {
  day = 86400,
  hour = 3600,
  minute = 60,
  second = 1,
}

export interface HcsAdminOrgApiComponent {
  id: number;
  applicationId: number;
  rateLimitClassId: number;
  deprecated: boolean;
  deprecatedSince: string | null;
  name: string;
  tier: string | null;
  uom: string | null;
}

export interface HcsAdminOrgApiComponentExclusion {
  id: number;
  component: HcsAdminOrgApiComponent;
  componentId: number;
  createdBy: string | null;
  createdOn: string | null;
  deletedBy: string | null;
  deletedOn: string | null;
  lastUpdatedBy: string | null;
  lastUpdatedOn: string | null;
  organizationId: number;
}

export type HcsAdminOrgApiComponentExclusionCreate = Pick<
  HcsAdminOrgApiComponentExclusion,
  'componentId' | 'organizationId'
>;

export interface HcsAdminOrgApiPrincipalComponent {
  id: number;
  component: HcsAdminOrgApiComponent;
  componentId: number;
  createdBy: string | null;
  createdOn: string | null;
  isActive: boolean;
  lastUpdatedOn: string;
  principalId: string;
  principalType: string;
  validFrom: string;
  validTill: string;
}

interface HcsAdminOrgApplicationBase extends Application {
  available: boolean;
  components: string[];
}

export interface HcsAdminOrgApplicationNotProvisionable
  extends HcsAdminOrgApplicationBase {
  provisionable: false;
}

export interface HcsAdminOrgApplicationProvisionable<
  ApplicationName extends AppSlugs,
  ProvisioningData
> extends HcsAdminOrgApplicationBase {
  provisionable: true;
  provisioningData: ProvisioningData;
  name: ApplicationName;
}

export interface HcsAdminLeadFeedProvisioningData {
  extraData: {
    createdAt: string;
    csvSortOrder: string[];
    deliveryEmail: string;
    deliveryMethod: string;
    deliveryPassword?: string;
    deliveryS3Prefix?: string;
    deliveryUrl: string;
    deliveryUsername?: string;
    emailSchedule: {
      crontab: string;
      enabled: boolean;
      timezone: string;
    };
    featureFlags: { createdAt: string; flag: string; id: number }[];
    hcOrgId: number;
    id: number;
    isDisabled: boolean;
    name: string;
    skipEmailDeliveries?: boolean;
    snapshotEmailSchedule?: boolean;
    updatedAt: string;
  };
  provisioned: true;
  name: AppSlugs.AcquisitionExplorer;
}

export interface HcsAdminOrderManagerProvisioningData {
  extraData: {
    calloutAuthMode?: string;
    calloutEnabled: boolean;
    calloutUrl: string;
    calloutUsername?: string;
    createdAt: string;
    debug: boolean;
    disableAllQc?: boolean;
    disableEmails: boolean;
    disableInspectionDelay: boolean;
    disableValuationDelay: boolean;
    enableAllQc: boolean;
    hcOrgId: string;
    id: number;
    ignoreValuationWarnings?: boolean;
    includeJson: boolean;
    inspectionPartner: number;
    integrationPartner: number;
    maxPercentInvalidItems?: number;
    routingRules: { orderTypes: string[]; priority: number }[];
    skipDuplicateAddressCheck: boolean;
    skipValueAnalysisCheck: boolean;
    supportedRestrictedOrderTypes: [];
    updatedAt: string;
    valuationPartner?: string;
  };
  name: AppSlugs.AgileSuite;
  provisioned: true;
}

export interface HcsAdminOrgProvisioningDataNotProvisioned {
  provisioned: false;
  name: AppSlugs.AcquisitionExplorer | AppSlugs.AgileSuite;
}

export type HcsAdminOrgApplication =
  | HcsAdminOrgApplicationNotProvisionable
  | HcsAdminOrgApplicationProvisionable<
      AppSlugs.AcquisitionExplorer,
      HcsAdminLeadFeedProvisioningData
    >
  | HcsAdminOrgApplicationProvisionable<
      AppSlugs.AgileSuite,
      HcsAdminOrderManagerProvisioningData
    >
  | HcsAdminOrgApplicationProvisionable<
      AppSlugs.AgileSuite | AppSlugs.AgileSuite,
      HcsAdminOrgProvisioningDataNotProvisioned
    >;

export interface HcsAdminOrgSubscription {
  active: boolean;
  application: Application;
  subscriptions: HcsAdminOrgApplicationSubscription[];
}

export interface HcsAdminOrgApplicationSubscription {
  active: boolean;
  applicationIds: number[];
  billingDayOfMonth?: string;
  components: HcsAdminSubscriptionComponent[];
  endDate?: DateStr;
  id: number;
  organizationId: number;
  startDate: DateStr;
  status: string;
  termEndDate?: DateStr;
  termStartDate: DateStr;
  zNotes?: string;
  zStatus: string;
  zSubscriptionId: string;
  zSubscriptionNumber: string;
  zuoraData: HcsAdminZuoraData;
}

export interface HcsAdminZuoraData {
  billingPeriod: string;
  includedUnits: number;
  lastChargedThough: string;
  model: string;
  monthlyFee: number;
  name: string;
  overagePrice: number;
  price: number;
  pricingSummary: string;
  productId: string;
  productName: string;
  productRatePlanChargeId: string;
  productRatePlanId: string;
  ratePlanCharges: HcsAdminZuoraRatePlanCharge[];
  ratePlanName: string;
  subscriptionStartDate: DateStr;
  termEndDate: DateStr;
  termStartDate: DateStr;
  type: string;
  uom: string;
}

export interface HcsAdminZuoraRatePlanCharge {
  billingPeriod: 'Annual' | 'Month';
  includedUnits?: number;
  overagePrice?: number;
  model: string;
  name: string;
  price: number;
  pricingSummary: string;
  productRatePlanChargeId: string;
  type: string;
  uom?: string;
}
export interface HcsAdminSubscriptionComponent {
  applicationId: number;
  rateLimitClassId: number;
  deprecated?: boolean;
  deprecatedSince?: boolean;
  id: number;
  name: string;
  tier: string;
  uom: string;
}

export type HcsAdminOrgUsageFilters = Partial<{
  created_at: string;
  creator_user_email: string;
  start_datetime: string;
  end_datetime: string;
  status: string;
  organization_id: number;
}>;
export type HcsAdminOrgUsagePagination = Partial<
  PaginationOptions & {
    sortBy: HcsAdminOrgUsageFilterFields;
    sortOrder: 'ASC' | 'DESC';
  }
>;

export type HcsAdminOrgUsageFilterFields = keyof HcsAdminOrgUsageFilters;
export type HcsAdminOrgUsagePaginationFields = keyof HcsAdminOrgUsagePagination;

export interface HcsAdminOrgUsageOptions {
  filters?: HcsAdminOrgUsageFilters;
  pagination?: HcsAdminOrgUsagePagination;
}

export type HcsAdminOrgUsageApiFilterOps = 'like' | 'ilike' | 'eq';
export interface HcsAdminOrgUsageApiFilter {
  name: HcsAdminOrgUsageFilterFields;
  val: string;
  op: HcsAdminOrgUsageApiFilterOps;
}

export interface HcsAdminOrgUsageApiParams {
  q: {
    filters?: HcsAdminOrgUsageApiFilter[];
    orderBy: [
      { field: HcsAdminOrgUsageFilterFields; direction: 'asc' | 'desc' }
    ];
  };
  page: number;
  resultsPerPage: number;
}
export interface HcsAdminUsageQuery {
  createdAt: string;
  creatorUserEmail: string;
  creatorUserId: string;
  endDatetime: string;
  fileName: string;
  id: number;
  numberOfRecords: number;
  organizationId: number;
  parameters: {
    ignoreNonbillable: boolean;
    maxReqTime: string;
    minReqTime: string;
    organizationId: number;
    propertySuccessStatus: string;
  };
  startDatetime: string;
  status: 'Completed' | 'Failed';
  statusDetails?: string;
  updatedAt: string;
}

export interface HcsAdminNewUsageQuery {
  address?: string;
  authReqId?: string;
  component?: string;
  endReqTime?: string;
  startReqTime?: string;
  ignoreNonbillable: boolean;
  propertySuccessStatus: 'all' | 'success_only' | 'failure_only';
}

export interface HcsAdminUsageQueryDownloadPayload {
  orgId: number;
  usageQueryId: number;
  fileName: string;
}
export interface HcsAdminNewOrg {
  name: string;
  active: boolean;
}

export interface HcsAdminImportSubscriptionsResponse {
  status: string;
  taskId: string;
}
