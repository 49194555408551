import React from 'react';
import { RouteObject } from 'react-router-dom';

import { TermsAndPrivacyLinks } from '@hcs/auth';
import {
  EntryPageContainer,
  LayoutBanner,
  LayoutGlobalHeaderChildren,
} from '@hcs/design-system';
import { APP_CONFIG_CANARYAI, VIEW_PATHS_CANARYAI } from '@hcs/hc-products';

import { PrivateApplicationPageRedirect } from '../../../auth-redirects/PrivateApplicationPageRedirect';
import { SolutionsPlatformGlobalHeader } from '../../../navigation/SolutionsPlatformGlobalHeader';
import { CanaryAISignupContainer } from '../components/CanaryAISignupContainer';
import { DemoImageContainer } from '../components/DemoImageContainer';
import { CanaryAI } from '../pages';
import { CanaryAISelfServiceSetupPage } from '../pages/CanaryAISelfServiceSetupPage';
import { CanaryAISelfServiceSignUpPage } from '../pages/CanaryAISelfServiceSignUpPage';

const CANARYAI_ROOT_PATH = APP_CONFIG_CANARYAI.rootPath;

const CANARYAI_ROUTE_OBJECT: RouteObject[] = [
  {
    path: APP_CONFIG_CANARYAI.rootPath,
    element: (
      <PrivateApplicationPageRedirect>
        <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
          <CanaryAI />
        </LayoutGlobalHeaderChildren>
      </PrivateApplicationPageRedirect>
    ),
  },
  {
    path: `${CANARYAI_ROOT_PATH}/${VIEW_PATHS_CANARYAI.SIGN_UP}`,
    element: (
      <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
        <EntryPageContainer>
          <LayoutBanner dataHcName="canaryai-self-service-signup">
            <CanaryAISignupContainer>
              <CanaryAISelfServiceSignUpPage />
              <DemoImageContainer />
            </CanaryAISignupContainer>
            <TermsAndPrivacyLinks />
          </LayoutBanner>
        </EntryPageContainer>
      </LayoutGlobalHeaderChildren>
    ),
  },
  {
    path: `${CANARYAI_ROOT_PATH}/${VIEW_PATHS_CANARYAI.SELF_SERVICE_SETUP}`,
    element: (
      <LayoutGlobalHeaderChildren header={<SolutionsPlatformGlobalHeader />}>
        <EntryPageContainer>
          <LayoutBanner
            dataHcName="canaryai-self-service-setup"
            title="CanaryAI"
            description="Let's setup your account."
          >
            <CanaryAISelfServiceSetupPage />
          </LayoutBanner>
        </EntryPageContainer>
      </LayoutGlobalHeaderChildren>
    ),
  },
];

export const routes = (): RouteObject[] => {
  return CANARYAI_ROUTE_OBJECT;
};
