import React from 'react';
import classNames from 'classnames';

import { formatNumber } from '@hcs/utils';

import { DirectionalChevron } from '../../../../foundations/svgs/icons/animated/DirectionalChevron';
import { IconButton } from '../../../controls-and-inputs/buttons/IconButton';

import styles from './Pagination.module.css';

interface PaginationTheme {
  Pagination?: string;
}

export interface PaginationProps {
  dataHcName: string;
  className?: string;
  page?: number | null;
  numPages?: number | null;
  totalCount?: number | null;
  theme?: PaginationTheme;
  hideResultCount?: boolean;
  hidePages?: boolean;
  onClickPrev?: VoidFunction;
  onClickNext?: VoidFunction;
}
// Equal to the height css rule in the module
export const PAGINATION_HEIGHT = 20;
export const Pagination = ({
  dataHcName,
  className,
  page,
  numPages,
  totalCount,
  theme,
  hideResultCount,
  hidePages,
  onClickNext,
  onClickPrev,
}: PaginationProps) => {
  return (
    <div
      data-hc-name={dataHcName}
      className={classNames(styles.Pagination, theme?.Pagination, className)}
    >
      {!hideResultCount && (
        <div>
          <span data-hc-name={`${dataHcName}-count`}>
            {formatNumber(totalCount)}
          </span>{' '}
          Result{totalCount && totalCount > 1 ? 's' : ''}
        </div>
      )}
      {!hidePages && (
        <div
          data-hc-name={`${dataHcName}-pagination-controls`}
          className={styles.PaginationControls}
        >
          Page{' '}
          <span data-hc-name={`${dataHcName}-current-page`}>
            {formatNumber(page)}
          </span>{' '}
          of{' '}
          <span data-hc-name={`${dataHcName}-num-pages`}>
            {formatNumber(numPages)}
          </span>
          <IconButton
            dataHcName={`${dataHcName}-prev-page`}
            icon={
              <DirectionalChevron
                direction="left"
                dataHcName={`${dataHcName}-prev-page-icon`}
                size="sm"
              />
            }
            onClick={onClickPrev}
            disabled={!page || page === 1}
          />
          <IconButton
            dataHcName={`${dataHcName}-next-page`}
            icon={
              <DirectionalChevron
                direction="right"
                dataHcName={`${dataHcName}-next-page-icon`}
                size="sm"
              />
            }
            onClick={onClickNext}
            disabled={!page || page === numPages}
          />
        </div>
      )}
    </div>
  );
};
