import { ApiKey } from '../analytics-api/ApiKeys.types';
import { AppSlugs } from '../auth';

import { ZuoraSubscription } from './ZuoraSubscriptions.types';

export type AuthHeaderFn = (token: string | null) =>
  | {
      [key: string]: string;
    }
  | undefined;

export interface AvailableOrganization {
  id: number;
  name: string;
  slug: string;
}

export interface OrgDomain {
  domain: string;
  organizationId: number;
}

export interface Organization {
  active: boolean;
  createdOn: string | null;
  domains: OrgDomain[];
  enterprise: boolean;
  id: number;
  name: string;
  salesforceAccountId: string;
  salesforceLeadId: string;
  lastSyncedWithM3Ter: string | null;
  slug: string;
  test: boolean;
  zAccountId: string;
  zAccountNumber: string;
  m3terAccountId: string | undefined; // for calling m3ter apis
  m3terAccountCode: string | undefined; // for submitting usage
}

/*
 * OrganizationWithApplications is the object returned from the "current-organization" endpoint
 * It shares many but not all of the same fields as the object returned in the "validate" endpoint (which is defined as Organization above)
 * In the "validate" endpoint response: response.current_organization has the Organization type above
 * But current-organization response has this OrganizationWithApplications type
 */
export interface OrganizationWithApplications extends Organization {
  applications: Application[];
}

// TODO: Need to figure out what all these roles mean exactly.
export enum Roles {
  // Platform Admin (ehrmantraut, going away with swtich to account-api)
  HcAdmin = 'Administrator',
  // Standard External Roles:
  OrgAdmin = 'Power User', // Org Admin
  User = 'User',
  // Internal Roles for Applications
  AgileOps = 'Agile Ops',
  Broker = 'Broker',
  CanaryAdmin = 'Canary Admin',
  BulkData = 'Bulk Data Submit',
  ClientAdmin = 'Client Admin',
  PlatformAdmin = 'Platform Admin',
  EngTools = 'Engineering Tools',
  ReportRepair = 'Report Repair',
  // Agile Roles
  OmView = 'OM View',
  OmCreate = 'OM Create',
}
export interface Role {
  id: number;
  name: Roles;
  description: string | null;
}
export interface RolesResponse {
  numResults: number;
  objects: Role[];
  page: number;
  totalPages: number;
}

export interface Application {
  displayName: string;
  id: number;
  name: AppSlugs;
  termsUrl: string;
  url: string;
}

export interface UserApplication {
  application: Application;
  applicationId: Application['id'];
  createdBy: null; // not sure about this one
  createdOn: string;
  id: number;
  state: 'allow' | 'grant' | 'deny'; // not sure if there are more states
  userId: User['id'];
}

export type UserApplicationPayload = Pick<
  UserApplication,
  'applicationId' | 'userId' | 'state'
>;

export interface MlsMembership {
  hcMlsId: number;
  id: number;
  mlsAcronym: string | null;
  mlsName: string;
  userId: number;
  userMlsId: string;
}

export interface User {
  acceptedTerms: boolean;
  acceptedTermsOn: string | null;
  active: boolean;
  apiAuthKeys: ApiKey[];
  city: string;
  companyName: string;
  confirmed: boolean;
  confirmedOn: string | null;
  createdOn: string;
  email: string;
  firstName: string | null;
  hasSfdcContact: boolean | null; // Not sure this is correct type
  id: number;
  lastActive: string;
  lastLoggedInOn: string;
  lastName: string | null;
  mlsMembership: MlsMembership[];
  organizationId: number;
  organization: Organization | null;
  phone: string | null; // Not sure if this is string or number
  registerApplication: string | null; // Not sure what this is
  roles: Role[];
  state: string | null; // Is it worth making state an enum?
  streetAddress: string | null;
  stripeCustomerId: string | null; // Not sure if this is correct type
  userApplications: UserApplication[];
  zipCode: string | null;
}
export interface Validity {
  expiration: number;
  expiresOn: number;
  token: string;
}

export interface Account {
  // All active zuora subscriptions
  // ONLY apps w/ zuora components can appear in this list
  accessibleApplications: ZuoraSubscription[];
  // All applications a user has ever had access to.
  // They still have access to the UI to retrieve what they own,
  // but they cannot pull new assets (e.g. PEXP reports)
  applications: AppSlugs[];
  currentOrganization: Organization;
  subscriptionType: 'enterprise' | 'self-service';
  user: User;
  validity: Validity;
}

export interface AccountTokenResponse {
  token: {
    access: string;
    accessExp: string;
  };
}

export interface SingleUseTokenResponse {
  singleUseToken: string;
}
