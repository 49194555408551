import {
  CapabilityapiCapabilitiesResponse as CapabilityapiCapabilitiesResponseExt,
  CapabilityapiCapabilityResp as CapabilityapiCapabilityRespExt,
} from './generated/AccountApi.types';
import {
  CapabilityapiCapabilitiesResponse as CapabilityapiCapabilitiesResponseInt,
  CapabilityapiCapabilityResp as CapabilityapiCapabilityRespInt,
  EntitlementapiEntitlementOrgResp as EntitlementapiEntitlementOrgRespInt,
} from './generated/ManagerApi.types';

export type OrgEntitlementStatus = 'active' | 'expired' | 'upcoming';
export interface OrgEntitlementInt extends EntitlementapiEntitlementOrgRespInt {
  status: OrgEntitlementStatus;
}
export enum CapabilityApplications {
  AcquisitionExplorer = 'aexp',
  AgileSuite = 'agile-suite',
  BulkPropertyData = 'bulk-data',
  BulkHpi = 'bulk-hpi',
  BulkRpi = 'bulk-rpi',
  CanaryAI = 'canaryai',
  DataExplorer = 'dexp',
  InternalAgileOps = 'internal-agile-ops',
  InternalBulkData = 'internal-bulk-data',
  InternalEngTools = 'internal-eng-tools',
  InternalClientAdmin = 'internal-client-admin',
  InternalPlatformAdmin = 'internal-platform-admin',
  MarketInsights = 'market-insights',
  MarketPulse = 'market-pulse',
  OrgSettings = 'org-settings',
  Platform = 'platform',
  PortfolioMonitor = 'portfolio',
  PropertyExplorer = 'pexp',
  UserSettings = 'user-settings',
}
// TODO: Add this to generated types via custom script
export type CapabilityKeys =
  | 'appraisal-compare-report'
  | 'effective-date-dynamic-report'
  | 'effective-date-static-report'
  | 'offer-now'
  | 'pexp-dynamic-report'
  | 'pexp-editable-links'
  | 'pexp-editable-links-forgo-scrubbing'
  | 'pexp-manage-org-settings'
  | 'pexp-product-access'
  | 'pexp-static-report'
  // Internal Capabilities
  | 'client-admin-edit';

// TODO: Add this to generated types via custom script
export enum CapabilityCategories {
  Endpoint = 'endpoint',
  Report = 'report',
  ProductAccess = 'product-access',
  AppAccess = 'app-access',
}

export type CapabilitiesResponse =
  | CapabilityapiCapabilitiesResponseInt
  | CapabilityapiCapabilitiesResponseExt;

export type Capability =
  | CapabilityapiCapabilityRespInt
  | CapabilityapiCapabilityRespExt;

export type AccountApplicationType =
  | 'all'
  | 'external'
  | 'product'
  | 'external-app'
  | 'internal';

export type CapabilitiesFilters = Partial<{
  applicationType: AccountApplicationType;
  application: string | null;
  category: string | null;
  search: string | null;
}>;

export type ApplicationsFilters = Partial<{
  applicationType: AccountApplicationType;
}>;

export type EntitlementDefsFilters = Partial<{
  application: string | null;
  search: string | null;
}>;

export type RoleType = string;

export type RolesFilters = Partial<{
  type: RoleType | null;
  search: string | null;
}>;
